<template>
    <div class="about">

        <AppHeading title="Team" />

        <AppOurTeam />

    </div>
</template>

<script>
export default {
    components: {
        AppHeading: () => import("@/components/Heading.vue"),
        AppOurTeam: () => import("@/components/OurTeam.vue"),
    }
}
</script>